var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"3"}},[_c('text-field',{staticClass:"search-field",attrs:{"outlined":"","label":"Search Member","placeholder":"Type member's name or email@","maxlength":"255"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('primary-button',{attrs:{"short":""},on:{"click":_vm.newMemberModal}},[_vm._v(" Invite Member ")])],1),_c('v-col',{attrs:{"md":"12"}},[_c('data-table',{staticClass:"mt-5",attrs:{"search":_vm.search,"show-select":"","item-key":"member_card_id","loading":_vm.loading,"items":_vm.members,"headers":_vm.headers,"items-per-page":_vm.membersMetaPerPage,"hide-footer":true,"server-items-length":_vm.membersMetaTotal,"page":_vm.membersMetaCurrentPage,"custom-columns":['item.time_tracking_status', 'item.member']},scopedSlots:_vm._u([{key:"custom-render-item.time_tracking_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"dot-icon mr-2",class:"success-dot",attrs:{"x-small":""}},[_vm._v(" fiber_manual_record ")]),_vm._v(" "+_vm._s(item.time_tracking_status ? 'Enabled' : 'Disabled')+" ")],1)]}},{key:"custom-render-item.member",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"custom-member"},[_c('img',{staticClass:"custom-member-img",attrs:{"src":item.image,"alt":item.name,"title":item.name}})]),_c('span',{staticClass:"custom-member__name"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.project_roles",fn:function(ref){
var project_roles = ref.item.project_roles;
return [(project_roles.length)?_vm._l((project_roles),function(role,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(role)+" ")])}):[_vm._v(" Project-user ")]]}},{key:"actions-menu",fn:function(ref){
var item = ref.item;
return [_c('data-table-actions',[_c('data-table-action-item',{attrs:{"icon":"drive_file_rename_outline"},on:{"click":function($event){return _vm.editMember(item.member_card_id)}}})],1)]}},{key:"footer",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.members.length),expression:"members.length"}],staticClass:"v-data-footer"},[_c('base-pagination',{attrs:{"pagination-meta":_vm.membersMeta,"has-pagination-pages":false,"has-counter-items-on-page":true},on:{"change-page":_vm.changePaginationPage},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1)]},proxy:true}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('table-checked-bar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('secondary-button',{attrs:{"small":""},on:{"click":function($event){_vm.confirmInactive = true}}},[_vm._v(" Move ")])],1),_c('v-col',[_c('secondary-button',{attrs:{"small":""},on:{"click":function($event){_vm.confirmStatus = true}}},[_vm._v(" Delete ")])],1),_c('v-col',[_c('text-button',{attrs:{"small":""},on:{"click":_vm.resetSelectedRows}},[_vm._v(" Cancel ")])],1)],1)],1),(_vm.newStatus)?_c('custom-dialog',{attrs:{"title":"Add Member","loading":_vm.loadingInvite,"btn-text":"Send Invitation"},on:{"save":_vm.saveNewMember},model:{value:(_vm.newStatus),callback:function ($$v) {_vm.newStatus=$$v},expression:"newStatus"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"invite"},[_c('v-row',{staticClass:"flex-column"},[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-field',{attrs:{"full-size":"","label":"Email","type":"email","outlined":"","error-messages":errors},model:{value:(_vm.formData.invited_email),callback:function ($$v) {_vm.$set(_vm.formData, "invited_email", $$v)},expression:"formData.invited_email"}})]}}],null,false,721403253)})],1),_c('v-col',{attrs:{"md":"12"}},[_vm._l((_vm.formData.init_membership),function(item,index){return _c('v-row',{key:index,staticClass:"flex-column"},[_c('v-col',{staticClass:"py-0 d-flex align-center"},[_c('validation-provider',{staticStyle:{"width":"100%"},attrs:{"name":"project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-select',{ref:"select",refInFor:true,staticStyle:{"width":"100%"},attrs:{"label":"Project","item-value":"id","item-text":"name","item-disabled":"disabled","no-data-text":"No projects found","loading":_vm.projects.loading,"outside":"","project":"","items":_vm.projects.items,"error-messages":errors},model:{value:(item.project_id),callback:function ($$v) {_vm.$set(item, "project_id", $$v)},expression:"item.project_id"}})]}}],null,true)}),(index > 0)?_c('text-button',{staticClass:"close-btn",attrs:{"small":""},on:{"click":function($event){return _vm.removeProject(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" close ")])],1):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{attrs:{"label":"Role","item-text":"name","item-value":"alias","items":_vm.roles,"error-messages":errors},model:{value:(item.role_alias),callback:function ($$v) {_vm.$set(item, "role_alias", $$v)},expression:"item.role_alias"}})]}}],null,true)})],1)],1)}),_c('v-col',{staticClass:"pl-0",attrs:{"md":"12"}},[_c('text-button',{staticClass:"add-btn",on:{"click":_vm.addProject}},[_c('v-icon',{staticClass:"primary-icon mr-2"},[_vm._v(" add_circle ")]),_vm._v(" Add project ")],1)],1)],2)],1)],1)],1)],1):_vm._e(),(_vm.editStatus)?_c('custom-dialog',{attrs:{"content-load":_vm.loadingModal,"title":"Edit Member"},on:{"save":_vm.saveEditedMember},model:{value:(_vm.editStatus),callback:function ($$v) {_vm.editStatus=$$v},expression:"editStatus"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('label',{staticClass:"member-title"},[_vm._v(" Member ")]),_c('v-row',{staticClass:"member-row"},[(_vm.formDataEdit.image)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.formDataEdit.image,"alt":""}}):_c('img',{staticClass:"avatar",attrs:{"src":require("@/static/icons/avatar.png"),"alt":""}}),_c('p',{staticClass:"d-flex align-center user-name"},[_vm._v(" "+_vm._s(_vm.formDataEdit.name)+" ")])])],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-field',{attrs:{"full-size":"","label":"Email","type":"email","maxlength":"255","outlined":"","error-messages":errors},model:{value:(_vm.formDataEdit.invited_email),callback:function ($$v) {_vm.$set(_vm.formDataEdit, "invited_email", $$v)},expression:"formDataEdit.invited_email"}})]}}],null,false,3516042053)})],1),_c('v-col',{attrs:{"md":"12"}},[_vm._l((_vm.formDataEdit.membership),function(item,index){return _c('v-row',{key:index},[_c('v-col',{staticClass:"py-0",attrs:{"md":"10"}},[_c('validation-provider',{attrs:{"name":"project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{attrs:{"label":"Project","item-text":"name","item-value":"id","item-disabled":"disabled","no-data-text":"No projects found","outside":"","items":_vm.projects.items,"loading":_vm.projects.loading,"error-messages":errors},model:{value:(item.project_id),callback:function ($$v) {_vm.$set(item, "project_id", $$v)},expression:"item.project_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0 d-flex justify-center align-center",attrs:{"md":"2"}},[(_vm.formDataEdit.membership.length > 1)?_c('text-button',{staticClass:"close-btn",attrs:{"small":""},on:{"click":function($event){return _vm.removeProject(index, 'edit')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" close ")])],1):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{attrs:{"label":"Role","item-text":"name","item-value":"alias","items":_vm.roles,"error-messages":errors},model:{value:(item.role_alias),callback:function ($$v) {_vm.$set(item, "role_alias", $$v)},expression:"item.role_alias"}})]}}],null,true)})],1)],1)}),_c('v-col',{staticClass:"pl-0",attrs:{"md":"12"}},[_c('text-button',{staticClass:"add-btn",on:{"click":function($event){return _vm.addProject('edit')}}},[_c('v-icon',{staticClass:"primary-icon mr-2"},[_vm._v(" add_circle ")]),_vm._v(" Add project ")],1)],1)],2)],1)],1)],1)],1):_vm._e(),_c('confirm-modal',{attrs:{"title":"Delete Member"},on:{"save":_vm.deleteMembers},model:{value:(_vm.confirmStatus),callback:function ($$v) {_vm.confirmStatus=$$v},expression:"confirmStatus"}},[_vm._v(" Are you sure you want to delete these members? ")]),_c('confirm-modal',{attrs:{"title":"Move Member"},on:{"save":_vm.addToInactive},model:{value:(_vm.confirmInactive),callback:function ($$v) {_vm.confirmInactive=$$v},expression:"confirmInactive"}},[_vm._v(" Are you sure you want to move these members? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
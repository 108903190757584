<template>
  <v-btn
    depressed
    outlined
    class="clockyme-button clockyme-button__secondary"
    :class="[
      {'short-size': short},
      {'small-size': small},
    ]"
    :ripple="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: {
    short: Boolean,
    small: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/clockyme/buttons.scss";

  .short-size {
    max-width: 132px !important;
    height: 40px !important;
  }

  .small-size {
    max-width: 84px !important;
    height: 40px !important;
  }
</style>

<template>
  <icon-button @click="$emit('click', $event)">
    <v-icon v-if="icon" color="grey">
      {{ icon }}
    </v-icon>

    <slot />
  </icon-button>
</template>

<script>
import IconButton from '@/components/ui/Buttons/IconButton';

export default {
  name: 'DataTableActionItem',
  components: { IconButton },
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <v-card
    height="300"
    flat
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="-1"
      color="white"
    >
      <slot />
    </v-snackbar>
  </v-card>
</template>

<script>

export default {
  name: 'TableCheckedBar',
  props: {
    value: Boolean,
  },
  data() {
    return {
      snackbar: this.value,
    };
  },
  watch: {
    value(val) {
      this.snackbar = val;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  ::v-deep .v-snack__wrapper {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: $spacing-4 $spacing-5;
    .v-snack__content {
      padding: 0;
    }
  }
</style>

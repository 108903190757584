<template>
  <div class="pagination-wrapper">
    <div v-if="hasCounterItemsOnPage" class="v-data-footer__pagination">
      {{ paginationMeta.from }}-{{ paginationMeta.to }} of
      {{ paginationMeta.total }}
    </div>
    <v-pagination
      v-model="page"
      class="base-pagination"
      :class="{'only-arrow': !hasPaginationPages}"
      :length="paginationMeta.last_page"
      :total-visible="hasPaginationPages ? undefined : 0"
      @input="$emit('change-page', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'BasePagination',
  props: {
    hasPaginationPages: {
      type: Boolean,
      default: true,
    },
    hasCounterItemsOnPage: {
      type: Boolean,
      default: false,
    },
    paginationMeta: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    page: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 36px;

  .only-arrow {
    ::v-deep .v-pagination__navigation {
      box-shadow: none;
      margin: 0;
    }
    ::v-deep .v-pagination {
      gap: 17px;
    }
  }
}
</style>

export default {
  methods: {
    setQueryParams(params) {
      const obj = { ...this.$route.query };

      Object.keys(params).forEach((key) => {
        const value = params[key];

        if (value) {
          obj[key] = value;
        } else {
          delete obj[key];
        }
      });
      this.$router.replace({
        ...this.$router.currentRoute,
        query: obj,
      }).catch(() => {});
    },
    updateDataByQueryParams(query) {
      Object.keys(query).forEach((key) => {
        if (key === 'member' || key === 'project' || key === 'page') {
          this.params[key] = +query[key];
        } else {
          this.params[key] = query[key];
        }
      });
    },
  },
};

<template>
  <div class="actions-wrap">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DataTableActions',
};
</script>

<style lang="scss" scoped>
  .actions-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>

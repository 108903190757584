<template>
  <div>
    <span v-if="outside" class="select-outside-label">
      {{ label }}
    </span>
    <v-select
      v-model="selected"
      class="custom-select"
      :class="[
        {'large': large}
      ]"
      :item-text="itemText"
      :item-value="itemValue"
      :label="outside ? '' : label"
      :items="items"
      :hide-selected="false"
      :return-object="returnObject"
      v-bind="$attrs"
      append-icon="keyboard_arrow_down"
      outlined
      @change="$emit('change', $event)"
      @input="$emit('input', selected)"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
      <template v-slot:append>
        <v-icon
          :class="arrowClass"
          small
        >
          keyboard_arrow_down
        </v-icon>
      </template>
      <template v-if="project" v-slot:item="{ item }">
        <v-icon
          x-small
          class="dot-icon mr-2"
          :style="{color: item.color_hex}"
        >
          fiber_manual_record
        </v-icon>
        <span>
          {{ item.name }}
        </span>
      </template>
      <template v-if="project" v-slot:selection="{ item }">
        <v-icon
          x-small
          class="dot-icon mr-2"
          :style="{color: item.color_hex}"
        >
          fiber_manual_record
        </v-icon>
        <span>
          {{ item.name }}
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    items: {
      type: [Array, Object],
      default: undefined,
    },
    itemText: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: null,
    },
    outside: Boolean,
    project: Boolean,
    arrowClass: {
      type: String,
      default: 'white-icon',
    },
    large: Boolean,
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  watch: {
    value(val) {
      this.selected = val;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .select-outside-label {
    display: block;
    color: $grey;
    font-size: $type-xs;
    margin-bottom: $spacing-2;
  }

  .custom-select {
    border-radius: $radius-3;
    ::v-deep.v-input__slot {
      min-height: 30px;
      padding: 0 16px !important;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        max-width: 130px;
      }
      .v-select__selections {
        flex-wrap: nowrap;
      }
    }
  }

  .large {
    ::v-deep .v-input__control {
      min-width: 400px !important;
      max-width: 400px !important;
    }
  }
</style>

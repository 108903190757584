export const MEMBERS = [
  { text: 'Member', value: 'member' },
  { text: 'Role', value: 'project_roles' },
  { text: 'Projects', value: 'project_count' },
  { text: 'Time Tracking', value: 'time_tracking_status' },
  // { text: 'Activity', value: 'activity' },
];
export const MEMBERS_UNCONFIRMED = [
  { text: 'Email', value: 'email' },
];
